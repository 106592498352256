.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .rounded-circle-container {
    border-radius: 50%;
    overflow: hidden; /* Pour s'assurer que l'image respecte la bordure arrondie */
    border: 0.5px solid rgb(158, 158, 158);
    padding: 3px;
  }

.navbar-nav .nav-item.active a {
    color: rgb(255, 0, 0);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Espace entre les conteneurs */
  padding: 20px;
}

.rounded-container {
  border-radius: 10px;
  background-color: #ffffff; /* Couleur de fond */
  padding: 10px; /* Espace intérieur */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Ombre légère */
}

.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-row2
{
  display: flex;
  align-items: center;
}

.small-text {
  font-size: 14px;
}

.titre-text
{
 /* Couleur de fond */
  margin-bottom: -15px;
  font-size: 30px;
}

.small-image {
  width: 100px; /* Ajustez la taille de l'image selon vos besoins */
  height: auto;
}

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  