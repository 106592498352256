.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .rounded-circle-container {
    border-radius: 50%;
    overflow: hidden; /* Pour s'assurer que l'image respecte la bordure arrondie */
    border: 0.5px solid rgb(158, 158, 158);
    padding: 3px;
  }

.navbar-nav .nav-item.active a {
    color: rgb(255, 0, 0);
}

.font-bold {
  font-weight: bold;
}

.navbar-custom {
  background-color: #ffffff; /* Vous pouvez utiliser n'importe quelle couleur que vous préférez */
}

.text-nav-colors{
  color: #0077ff;
}

.divFirst
{
  /*border-bottom: 0.5px solid rgb(117, 117, 117);*/
  color: #ffffff;
}

.nav-item:hover .nav-link {
  color: #000000;
}

.nav-link:hover {
  color: #000000;
}


  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  